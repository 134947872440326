<template>
  <div class="container mb-6">
    <v-card flat color="transparent" class="d-flex mb-4">
      <v-avatar rounded class="col-6" size="200">
        <img :src="user.userImage" loading="lazy" :alt="user.userName">
      </v-avatar>
      <div class="ml-md-10 col-6 text-h6 align-self-center">
        <p>Имя: {{ user.userName }}</p>
        <p>Фамилия: {{ user.userSurname }}</p>
        <p>Возраст: {{ user.userAge }} лет</p>
        <p>Вес: {{ user.userWeight }} кг</p>
      </div>
    </v-card>

    <div class="black--text mb-6">
      <!-- <p><span class="mb-1 ml-4 body-1 font-weight-bold">Статус:</span> {{ user.userRank }}</p> -->
      <p class="mb-1 ml-4 body-1 font-weight-bold">Общая информация:</p>
      <p class="ml-4">{{ user.userDescription }}</p>
      <p class="mb-1 ml-4 body-1 font-weight-bold">Участие в турнирах:</p>
      <div v-if="user.userCompetitions" class="mb-6 ml-4">
        <ul v-for="(competition, i) in user.userCompetitions" :key ="i">
          <li>Название турнира: <span class="body-1"><strong>{{ competition.name }}</strong></span>, место: <b><i>{{ competition.place }}</i></b></li>
        </ul>
      </div>
      <div v-else>
        <p class="ml-4">Спортсмен еще не принимал участия в соревнованиях</p>
      </div>
    </div>

    <v-btn :to="{ name: 'Register', id: user.userId }" dark color="red mt-4 mt-md-6">
      Редактировать профиль
    </v-btn>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex'

export default {
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isAuthenticated'])
  }
}
</script>

